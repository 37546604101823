<template>
	<!-- 
		Image 
	-->
	<div v-if="imageUrl != null" style="padding:20px 20px 5px 20px;" class="text-right">
		<ion-img :src="imageUrl" />
		<ion-button v-if="imageUrl != null" size="small" color="danger" @click="imageUrl = null">
			<ion-icon :icon="trash"></ion-icon> Delete Image
		</ion-button>
	</div>
	<!-- 
		URLs 
	
	<div v-if="urls != null">
		<div v-if="urls.length > 0" style="padding:20px 20px 5px 20px;">
			<div v-for="url in urls" :key="url" style="border-bottom:1px solid #eeeeee;">
				{{url}}
			</div>
		</div>
	</div>-->
	<!-- 
		Input Row 
	-->
	<ion-grid>
		<ion-row>
			<ion-col size="2" class="text-center">
				<ion-button size="small" color="light" @click="takePicture()">
					<ion-icon :icon="camera" />
				</ion-button>
			</ion-col>
			<ion-col size="8">
				<ion-input clear-input value="Cancel" style="background:#f4f5f8;border-radius:20px;padding-left:15px!important;" placeholder="Enter a comment" v-model="comment" />
			</ion-col>
			<ion-col size="2" class="text-center">
				<ion-button size="small" :color="btnColor" @click="storeComment()">
					<ion-icon :icon="paperPlane" />
				</ion-button>
			</ion-col>
		</ion-row>
	</ion-grid>
</template>

<script>
import { IonGrid, IonRow, IonCol, IonInput, IonButton, IonIcon, IonImg } from '@ionic/vue';
import { defineComponent, ref, computed, onMounted } from 'vue';
import { useStore } from "vuex";
import { paperPlane, camera, trash } from 'ionicons/icons'
//import { Plugins } from '@capacitor/core';
//const { Keyboard } = Plugins;
import apiClient from '../services/api';
const { Camera } = Plugins;
import { Plugins, CameraSource, CameraResultType } from "@capacitor/core";

export default defineComponent( {
	components: { IonGrid, IonRow, IonCol, IonInput, IonButton, IonIcon, IonImg },
	name: 'CommentBox',
	props: ['postId', 'commentId'],
	setup(props) {
		const store = useStore();
		const commentReply = computed(() => store.state.commentReply);
		const commentToEdit = computed(() => store.state.commentToEdit);
		const comment = ref(null);
		const btnColor = ref('light');
		const isError = ref(false);
		const errors = ref([]);
		const imageUrl = ref(null);
		const urls = ref(null);

		onMounted(() => {
			if(commentToEdit.value) {
				comment.value = commentToEdit.value
			}
		})

		function storeComment() {
			let postType = 'store'
			if(commentToEdit.value) {
				postType = 'update'
			}
			// set loading
			apiClient.post('/api/'+ postType +'/comment', 
				{
					post_id: props.postId, 
					comment_id: props.commentId, 
					image: imageUrl.value,
					body: comment.value,         
				},
				{
					headers: {
						'Authorization':  `Bearer ${store.state.authToken}`
					}
				}).then(response => {
					// Success
					if(response.data.message == 'success') {
						store.commit('setReloadSinglePost', true)
						store.commit('setCommentId', 0)
						store.commit('setCommentToEdit', null)
						// set open toast / 'Your comment has been posted.'
						store.commit('setToastMessage', 'Your comment has been posted.')
						store.commit('setToast', true)
						isError.value = false
						errors.value = []
						resetForm()
					}
					// Fail Validation
					if(response.data.message == 'fail-validation') {
						// set open toast / 'There was a problem.'
						store.commit('setToastMessage', 'There was a problem.')
						store.commit('setToast', true)
						isError.value = true
						errors.value = response.data.errors            
					}
					// Fail Auth
					if(response.data.message == 'fail-auth') {
						// set open toast / 'You must be signed in to comment.'
						store.commit('setToastMessage', 'You must be signed in.')
						store.commit('setToast', true)
					}
				}).catch(error => {
					// credentials didn't match
					isError.value = true
					errors.value = error.response.data.errors
				});        

		}

		async function takePicture() {
			let isAvailable = true;
			if (!isAvailable) {
				// Have the user upload a file instead
				alert("No Camera Aailable");
			} else {
				// Otherwise, make the call:
				try {
					const image = await Camera.getPhoto({
						quality: 90,
						allowEditing: true,
						resultType: CameraResultType.DataUrl,
						source: CameraSource.Prompt
					});
					console.log("image", image);
					// image.base64_data will contain the base64 encoded result as a JPEG, with the data-uri prefix added
					imageUrl.value = image.dataUrl;
					// can be set to the src of an image now
					console.log(image);
				} catch (e) {
					console.log("error", e);
				}
			}
		}

		function resetForm() {
			comment.value = null
			imageUrl.value = null
			urls.value = null
		}

		function detectURLs(text) {
			var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
			urls.value = text.match(urlRegex)
		}

		return {
			store, paperPlane, camera, comment, btnColor, storeComment, commentReply, imageUrl, takePicture, trash, detectURLs, urls, commentToEdit
		}
	},
	methods: {

	},
	watch: {
		comment: function() {
			if(this.comment != null && this.comment != '') {
				this.btnColor = 'primary'
					this.detectURLs(this.comment)
			} else {
				this.btnColor = 'light'
			}
		},
		commentReply: {
			handler() {
				if(this.commentReply != []) {
					this.comment = '@'+ this.commentReply.username+' '
				} else {
					console.log('fail')
				}
			},
			deep:true
		},
		commentToEdit: function() {
			if(this.commentToEdit) {
				this.comment = this.commentToEdit
			}
		}
	}

});
</script>

<style scoped>
	ion-icon {
		font-size:16px;
	}
</style>