<template>
  <ion-page id="main-content">

    <!-- Header - Auth -->
    <div v-if="isAuth === true && slug">
      <Header page="post-single" :slug="slug" />
    </div>
    <!-- Header - Public -->
    <div v-else>
      <ion-header id="app-header" :translucent="true">
        <ion-toolbar class="text-center">
          <ion-buttons slot="start">
            <ion-button @click.prevent="updateRoute('/')">
              <ion-icon :icon="menu"></ion-icon>
            </ion-button>
          </ion-buttons>
          <ion-buttons slot="end">
            <ion-button @click.prevent="updateRoute('/sign-in')">
              <ion-icon :icon="ellipsisHorizontal"></ion-icon>
            </ion-button>
          </ion-buttons>

          <LogoMark color="light" />

        </ion-toolbar>
      </ion-header> 
    </div>

    <!-- Page Content -->
    <ion-content color="light" :fullscreen="true">
      <!--  -->
      <div class="app-max-width bottom-padding">
        <div v-if="post">
          <ArticleCard :post="post" :is-auth="isAuth" display="page" />
        </div>
        <!-- Comment Box -->
        <div v-if="isAuth === true && post" class="comment-box">
          <CommentBox :post-id="post.id" :comment-id="commentId" />
        </div>
      </div>
    </ion-content>

    <!--Footer
    <ion-footer v-if="device != 'web' && isAuth === true">
      <Tabs page="post-single" />
    </ion-footer> -->

  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, loadingController, toastController } from '@ionic/vue';
import { defineComponent, ref, onMounted, computed } from 'vue';
import { search, options, menu, mail, arrowBack, chevronBack, ellipsisVertical, ellipsisHorizontal,settings, home, logIn, person  } from 'ionicons/icons';
import LogoMark from '../components/LogoMark.vue';
import doAuth from '../services/auth';
//import Tabs from '../components/Tabs.vue';
import Header from '../components/Header.vue';
import { useRouter } from 'vue-router';
import { isPlatform } from '@ionic/vue';
import ArticleCard from '../components/ArticleCard.vue';
import CommentBox from '../components/CommentBox.vue';
import { useStore } from "vuex";
import apiClient from '../services/api';
import { Plugins } from '@capacitor/core';
const { Browser } = Plugins;

export default defineComponent({
  name: 'PostSingle',
  components: {
    IonPage, IonContent, /*Tabs,*/ Header, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, LogoMark, ArticleCard, CommentBox
  }, 
  setup() {
    const router = useRouter();
    const store = useStore();
    const commentId = computed(() => store.state.commentId);
    const isAuth = ref(false);
    const device = ref('web');
    const slug = ref(null);
    const post = ref([]);
    const reloadSinglePost = computed(() => store.state.reloadSinglePost);
    const toastMessage = computed(() => store.state.toastMessage);
    const isToast = computed(() => store.state.isToast);
    const timeout = { default: 6000 }

    onMounted(() => {
      isAuth.value = doAuth('profile');

      slug.value = router.currentRoute._rawValue.params.slug
      
      if(slug.value != null && slug.value != undefined && slug.value != '') {
        getPost()
      } 
      // App
      if(isPlatform('ios') === true || isPlatform('android') === true)  {
        device.value = 'mobile-app'
      } 
      // Web
      else {
        device.value = 'web'
      }
    })

    function getPost() {
      presentLoading()

      apiClient.get('/api/get/post-by-slug/'+slug.value)
        .then(response => {
          post.value = response.data
          store.commit('setReloadSinglePost', false)
        }).catch(error => {
          console.log(error)
        });  
    }

    async function presentLoading() {
      const loading = await loadingController
      .create({
        cssClass: 'my-custom-class',
        message: 'Please wait...',
        duration: timeout,
      });

      await loading.present();

      setTimeout(function() {
        loading.dismiss()
      }, timeout);
    }

    async function goToLink() {
      await Browser.open({ url: post.value.url });
    }

    function updateRoute(r) {
      router.push(r)
    }

    async function openToast() {
      const toast = await toastController
      .create({
        //message: '',
        duration: 6000,
        message: toastMessage.value,
        position: 'bottom',
        buttons: [
        {
          icon: 'close',
          role: 'cancel',
          handler: () => {

          }
        }]
      })
      return toast.present();
    }

    return {
      updateRoute, router, device, search, options, menu, mail, arrowBack, chevronBack, ellipsisVertical, settings, home, logIn, person, post, slug, isAuth, ellipsisHorizontal, goToLink, commentId, openToast, isToast, reloadSinglePost, getPost, store
    }
  },
  watch: {
    reloadSinglePost: function() {
      if(this.reloadSinglePost === true) {
        this.getPost()
      }
    },
    isToast: function() {
      if(this.isToast === true) {
        this.openToast()
        this.store.commit('setToast', false)
      }
    }
  }

});
</script>

<style scoped>
.comment-box {
  background: #d7d8da;
  border-top:1px solid #eeeeee;
  position:fixed;
  bottom:0;
  left:0;
  right:0;
}
ion-thumbnail {
  --size:72px;
}



</style>
